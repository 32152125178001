/* eslint-disable */
class globalScript {
  constructor() {
    this.init()
  }

  async init() {
    await this.setObjectFitImages()
    await this.setPicturefill()
    await this.nav()
    await this.smoothScroll()
    await this.aos()
  }

  async smoothScroll(querySelector) {
    const { default: smoothScroll } = await import(/* webpackChunkName: 'module_smoothscroll' */ "./module/scroll/scroll")
    smoothScroll.smoothScrollAll('a[href^="#"]')
  }

  async setObjectFitImages() {
    const { default: objectFitImages } = await import(/* webpackChunkName: 'object-fit-images' */ "object-fit-images")
    const someImages = document.querySelectorAll("img.object-fit")
    objectFitImages(someImages)
  }

  async setPicturefill() {
    const { default: picturefill } = await import(/* webpackChunkName: 'picturefill' */ "picturefill")
    picturefill()
  }

  async nav() {
    const { default: Nav } = await import(/* webpackChunkName: 'Nav' */ "./module/nav/nav")
  }

  async aos() {
    const { default: AOS } = await import(/* webpackChunkName: 'aos' */ "aos")
    AOS.init({
      once: true,
    })
  }
}

export default new globalScript()
